.Nav {
  background: white;
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid var(--lightGrey);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.025);
}

.Nav--Container {
  display: flex;
  align-items: center;
  height: 6rem;
}

.Nav--Links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.Nav--Links > * + * {
  margin-left: 1rem;
}

.Nav .Logo {
  margin-right: 3rem;
  position: relative;
}

.Nav--MenuButton {
  display: none !important;
}

.NavLink {
  padding: 0.5rem 1rem;
  display: block;
  font-weight: 500;
  transition: color 0.2s, border-bottom-color 0.2s;
  color: inherit;
  text-decoration: none;
  border-bottom: 2px solid;
  border-bottom-color: transparent;
}

.NavLink:hover,
.NavLink.active,
.NavLink:focus {
  color: var(--primary);
  border-bottom-color: currentColor;
}

.Nav--Group {
  position: relative;
  cursor: pointer;
}

.Nav--GroupParent {
  padding-right: 3rem;
}

.Nav--GroupParent::after {
  content: '';
  position: absolute;
  top: calc(50% - 0.2rem);
  right: 1rem;
  border-bottom: 2px solid var(--darkGrey);
  border-right: 2px solid var(--darkGrey);
  display: block;
  height: 0.8rem;
  width: 0.8rem;
  transition: 0.3s ease all;
  transform: translateY(-50%) rotate(45deg);
}

.Nav--GroupLinks {
  position: absolute;
  top: 100%;
  left: 0;
  width: auto;
  background: white;
  /* opacity: 0; */
  max-height: 0;
  overflow: hidden;
  transition: 0.3s ease all;
}

.Nav--Group.active .Nav--GroupLinks {
  max-height: 100vh;
  opacity: 1;
  transition: 0.6s ease all;
}

.Nav--Group.active .Nav--GroupParent::after {
  top: calc(50% + 0.2rem);
  transform: translateY(-50%) rotate(-135deg);
}

.Nav--GroupLink {
  display: block;
  position: relative;
  width: 15rem;
}

@media (max-width: 600px) {
  .Nav--Container {
    display: flex;
  }

  .Nav--MenuButton {
    display: block !important;
    margin-left: auto;
    z-index: 1;
  }
  .Nav .Logo {
    z-index: 1;
  }

  .Nav--Links {
    display: none;
    align-items: center;
    flex-direction: column;
    position: fixed;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    animation: Nav--Links 0.2s ease;
  }

  .Nav--Links > * + * {
    margin-left: 0;
    margin-top: 1rem;
  }

  .Nav-active .Nav--Links {
    display: flex;
  }

  .Nav-active .NavLink {
    animation: Nav--Link 0.3s ease-out;
    animation-fill-mode: both;
  }

  .Nav .NavLink {
    font-size: 2.5rem;
    font-weight: 300;
  }

  .Nav .NavLink:nth-of-type(2) {
    animation-delay: 0.1s;
  }
  .Nav .NavLink:nth-of-type(3) {
    animation-delay: 0.2s;
  }
  .Nav .NavLink:nth-of-type(4) {
    animation-delay: 0.3s;
  }
  .Nav .NavLink:nth-of-type(5) {
    animation-delay: 0.4s;
  }
  .Nav .NavLink:nth-of-type(6) {
    animation-delay: 0.5s;
  }
  .Nav .NavLink:nth-of-type(7) {
    animation-delay: 0.6s;
  }
  .Nav .NavLink:nth-of-type(8) {
    animation-delay: 0.7s;
  }
  .Nav .NavLink:nth-of-type(7) {
    animation-delay: 0.6s;
  }

  .Nav--GroupLinks {
    position: relative;
    text-align: center;
  }

  .Nav--GroupLinks .NavLink {
    font-size: 1.5rem;
    width: auto;
  }
}

@keyframes Nav--Link {
  from {
    transform: translate(0rem, 1rem);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes Nav--Links {
  from {
    opacity: 0;
  }
}

.Button-blank {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
